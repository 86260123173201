import * as Sentry from '@sentry/react'

// Disable sentry for local development
const isDev = location.hostname === 'localhost' || location.hostname === '127.0.0.1'
const isEnabled = !isDev

export const sentryClient = {
  configure: ({ dsn, version, environment }: { dsn: string; version: string; environment: string }) => {
    isEnabled &&
      Sentry.init({
        dsn,
        environment,
        release: `grafana-k6-plugin@${version}`,
        normalizeDepth: 5,
      })
  },

  captureException: (...args: Parameters<typeof Sentry.captureException>) => {
    isEnabled && Sentry.captureException(...args)
  },

  captureEvent: (...args: Parameters<typeof Sentry.captureEvent>) => {
    isEnabled && Sentry.captureEvent(...args)
  },

  setUserContext: (user: any) => {
    isEnabled &&
      Sentry.configureScope(function (scope) {
        scope.setUser(user)
      })
  },

  clearUserContext: () => {
    isEnabled &&
      Sentry.configureScope(function (scope) {
        scope.setUser(null)
      })
  },
  disable: () => {
    const client = Sentry.getCurrentHub().getClient()
    if (client) {
      client.getOptions().enabled = false
    }
  },
  enable: () => {
    const client = Sentry.getCurrentHub().getClient()
    if (client) {
      client.getOptions().enabled = true
    }
  },

  setContext: (...args: Parameters<typeof Sentry.setContext>) => {
    isEnabled && Sentry.setContext(...args)
  },
}
