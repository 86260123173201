import { useDatasource } from 'datasourceContext'
import { TestRun } from 'types'
import { useFetch } from './useFetch'

export const useOverview = (testRun: TestRun) => {
  const { ds } = useDatasource()

  return useFetch([testRun, 'overview'], (testRun) => ds.fetchOverview(testRun.id), {
    useStickyData: true,
  })
}
