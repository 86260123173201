import { useRef, useEffect } from 'react'
import { SWRHook, Middleware } from 'swr'

/** This is a SWR middleware for keeping the data even if key changes. */
export const stickyData: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
  // Use a ref to store previous returned data.
  const previousDataRef = useRef<any>()

  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config)

  useEffect(() => {
    // Update ref if data is not undefined.
    if (swr.data !== undefined) {
      previousDataRef.current = swr.data
    }
  }, [swr.data])

  // Fallback to previous data if the current data is undefined.
  const dataOrStickyData = swr.data === undefined ? previousDataRef.current : swr.data

  return {
    ...swr,
    data: dataOrStickyData,
  }
}
