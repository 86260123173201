import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { K6DataSource } from 'datasource/datasource'

import { Environments, PROJ_ID_KEY } from './constants'
import { Project, Account } from 'types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useDatasource } from 'datasourceContext'

type AppContextType = {
  orgId?: number
  setOrgId: (orgId: number | undefined) => void
  isProd: boolean
  env: string
  project?: Project
  projectId: number
  setProjectId: (id?: number) => void
  account?: Account
}

type ProviderProps = {
  children: ReactNode
}

const AppContext = createContext<undefined | AppContextType>(undefined)

const getProject = async (ds: K6DataSource, projectId?: number) => {
  if (!projectId) {
    throw new Error('id is required')
  }

  const project = await ds.fetchProject(projectId)
  return project
}

const getDefaultProjectId = async (accountPromise: Promise<Account>) => {
  const account = await accountPromise
  return account.additional_user_data[0].last_used_project_id
}

const fallbackToDefaultProject = (accountPromise: Promise<Account>, history: any, projectId?: number) => async (
  exception: any
) => {
  const defaultProjectId = await getDefaultProjectId(accountPromise)
  if (defaultProjectId === projectId) {
    throw exception
  }
  history.push(`/projects/${defaultProjectId}`)
}

export const AppContextProvider = ({ children }: ProviderProps) => {
  const { ds } = useDatasource()
  const [orgId, setOrgId] = useState<number>()
  const [projectId, setProjectId] = useLocalStorage<number | undefined>(PROJ_ID_KEY, undefined)
  const [project, setProject] = useState<Project>()
  const [account, setAccount] = useState<Account>()
  const [accountPromise] = useState<Promise<Account>>(() => ds.fetchAccount())
  const history = useHistory()
  const { pathname } = useLocation()

  const pathMatch = matchPath<{ projectId: string }>(pathname, { path: '/projects/:projectId' })
  const projectFromParams = pathMatch?.params?.projectId

  const env = ds.k6ApiEnv ?? Environments.Production
  const isProd = env === Environments.Production

  useEffect(() => {
    accountPromise.then(setAccount)
  }, [accountPromise])

  useEffect(() => {
    if (projectFromParams) {
      setProjectId(+projectFromParams)
    }
  }, [projectFromParams, setProjectId])

  useEffect(() => {
    getProject(ds, projectId)
      .then((project) => {
        setProjectId(project.id)
        setProject(project)
        setOrgId(project.organization_id)
      })
      .catch(fallbackToDefaultProject(accountPromise, history, projectId))
  }, [ds, projectId, setProjectId, accountPromise, history])

  if (!projectId) {
    return null
  }

  return (
    <AppContext.Provider value={{ orgId, setOrgId, isProd, env, project, projectId, setProjectId, account }}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return context
}
