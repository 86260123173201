import React, { createContext, useContext, useState, ReactNode } from 'react'

type Breadcrumb = {
  path: string
  name: JSX.Element | string
}

type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

type ProviderProps = {
  children: ReactNode
}

const BreadcrumbsContext = createContext<undefined | BreadcrumbsContextType>(undefined)

const defaultRoute = { path: '', name: <span>k6</span> }

export const BreadcrumbsContextProvider = ({ children }: ProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([defaultRoute])

  return <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>
}

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext)

  if (context === undefined) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsContextProvider')
  }

  return context
}
