import React, { useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { PanelChrome, PanelContextProvider, LegendDisplayMode, PanelContext } from '@grafana/ui'

import {
  ArrayDataFrame,
  LoadingState,
  PanelData,
  TimeRange,
  dateTime,
  FieldConfig,
  EventBusSrv,
  EventBusExtended,
} from '@grafana/data'

import { QueryType, TestRun } from 'types'
import { useTimeSeries } from 'data/useTimeSeries'
import { ClipBoard } from 'components/Clipboard'
import { useDatasource } from 'datasourceContext'
import { CenteredSpinner } from 'components/CenteredSpinner'

const getTypes = (runId: number) => [
  { method: 'value', metric: 'vus', unit: 'VUs', label: 'VUs', type: 'test_runs', id: runId },
  { method: 'rps', metric: 'http_reqs', unit: 'reqs/s', label: 'req rate', type: 'test_runs', id: runId },
  { method: '0.95', metric: 'http_req_duration', unit: 'ms', label: 'resp time', type: 'test_runs', id: runId },
  {
    method: 'nz_rps',
    metric: 'http_req_failed',
    unit: 'reqs/s',
    label: 'failed rate',
    type: 'test_runs',
    id: runId,
  },
]

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'multi',
  },
  tooltip: {
    mode: 'multi',
  },
}

const fieldConfig = {
  defaults: {
    custom: {
      spanNulls: true,
    },
  },
  overrides: [],
}

export const OverviewChart = ({ testRun }: { testRun: TestRun }) => {
  const { ds } = useDatasource()
  const types = useMemo(() => getTypes(testRun.id), [testRun.id])
  const { data: series } = useTimeSeries(testRun, types)

  const appEvents: EventBusExtended = new EventBusSrv()
  const context: PanelContext = {
    eventBus: appEvents,
    canAddAnnotations: () => false,
  }

  const getPanelData = () => {
    if (!series) {
      return undefined
    }

    let rangeValues: any[] = []

    const formattedSeries = (series || []).map((r, i) => {
      const values = r.value[0]?.values || []
      if (values.length > 0) {
        rangeValues = values
      }
      const label = types[i].label
      return values.map((v) => ({
        timestamp: new Date(v.timestamp),
        [label]: v.value,
      }))
    })

    if (rangeValues.length === 0) {
      return undefined
    }

    const from = rangeValues[0]?.timestamp
    const to = rangeValues[rangeValues.length - 1]?.timestamp
    const range: TimeRange = {
      from: dateTime(from),
      to: dateTime(to),
      raw: { from, to },
    }

    const frames = formattedSeries.map((item, i) => {
      const frame = new ArrayDataFrame(item)
      if (frame.fields && frame.fields.length > 0) {
        const cfg: FieldConfig = { unit: types[i].unit }
        frame.fields[1].config = cfg
      }
      return frame
    })

    const panelData: PanelData = {
      state: LoadingState.Error,
      series: frames,
      timeRange: range,
    }

    return panelData
  }

  const panelData = getPanelData()

  return (
    <div>
      <ClipBoard name={ds.name} queryType={QueryType.METRIC} testRun={testRun}></ClipBoard>
      <AutoSizer disableHeight>
        {(size) => (
          <PanelChrome width={size.width} height={300} leftItems={[<div key="spacer" style={{ height: 32 }} />]}>
            {(innerWidth, innerHeight) => (
              <>
                {!panelData && <CenteredSpinner />}
                {panelData && (
                  <PanelContextProvider value={context}>
                    <PanelRenderer
                      title=""
                      pluginId="timeseries"
                      onOptionsChange={() => {}}
                      width={innerWidth}
                      height={innerHeight}
                      data={panelData}
                      options={panelOptions}
                      fieldConfig={fieldConfig}
                    />
                  </PanelContextProvider>
                )}
              </>
            )}
          </PanelChrome>
        )}
      </AutoSizer>
    </div>
  )
}
