import React from 'react'
import styled from 'styled-components'

// @ts-ignore
const flagDir = require.context('circle-flags/flags')

const StyledFlag = styled.img`
  height: 100%;
`

export const Flag = ({ name }: { name: string }) => <StyledFlag src={flagDir(`./${name}.svg`)} />
