import React, { Fragment, ReactNode } from 'react'
import { Tooltip } from '@grafana/ui'
import styled from 'styled-components'

import { LoadZoneLabel } from './LoadZoneLabel'

const Container = styled.div<{ $gridColumns: number }>`
  font-size: 13px;
  display: grid;
  grid-template-columns: ${({ $gridColumns = 3 }) => `repeat(${$gridColumns}, auto)`};
  column-gap: 10px;
  row-gap: 8px;
`

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
`

const StyledLoadZoneLabel = styled(LoadZoneLabel)`
  margin: 0;
`

type Props = {
  children: ReactNode
  loadZones: Array<{
    id: string
    isPublic: boolean
    city: string
    country: string
    loadPercent: number
    size?: string
    publicIp?: string
  }>
}

export const LoadZoneTooltip = ({ loadZones, children }: Props) => {
  const hasPrivate = loadZones.some((lz) => lz.isPublic === false)

  const tooltipContent = (
    <Container $gridColumns={hasPrivate ? 4 : 3}>
      <Title>Load zone</Title>
      <Title>Public IP</Title>
      <Title>Size</Title>
      {hasPrivate && <Title>Private</Title>}

      {loadZones.map((zone) => (
        <Fragment key={zone.id}>
          <StyledLoadZoneLabel
            country={zone.country}
            city={zone.city}
            loadPercent={zone.loadPercent}
            isPublic={zone.isPublic}
          />
          <span>{zone.publicIp}</span>
          <span>{zone.size}</span>
          {hasPrivate && <span>{zone.isPublic ? '-' : 'Yes'}</span>}
        </Fragment>
      ))}
    </Container>
  )

  return (
    <Tooltip content={tooltipContent} placement="bottom">
      <div>{children}</div>
    </Tooltip>
  )
}
