import { useTheme2 } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { createTableTheme } from 'pages/styles'
import React from 'react'
import DataTable from 'react-data-table-component'
import { TestRun } from 'types'
import { HttpChart } from './HttpChart'
import { useHttpStats } from 'data/useHttpStats'

interface Props {
  run: TestRun
  ds: K6DataSource
}

export const HttpTab = ({ run, ds }: Props) => {
  const { data: http } = useHttpStats(run)
  const theme = useTheme2()

  const format = (key: string, row: any) => {
    const val: number = row.http_metric_summary?.duration?.[key] ?? 0
    const format = val.toFixed(1)
    return `${format}ms`
  }

  const Status = (props: { expected: boolean }) => {
    return <div style={{ backgroundColor: getStatus(props.expected), height: '44px' }}>&nbsp;</div>
  }

  const columns = [
    {
      name: '',
      // eslint-disable-next-line react/display-name
      selector: (row: any) => <Status expected={row.expected_response}></Status>,
      width: '4px',
      compact: true,
    },
    {
      name: 'URL',
      selector: (row: any) => row.name,
      minWidth: '250px',
    },
    {
      name: 'Method',
      selector: (row: any) => row.method,
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
    },
    {
      name: 'Count',
      selector: (row: any) => row.http_metric_summary.requests_count.toLocaleString(),
    },
    {
      name: 'Min',
      selector: (row: any) => format('min', row),
    },
    {
      name: 'Stddev',
      selector: (row: any) => format('stdev', row),
    },
    {
      name: 'P95',
      selector: (row: any) => format('p95', row),
    },
    {
      name: 'P99',
      selector: (row: any) => format('p99', row),
    },
    {
      name: 'Max',
      selector: (row: any) => format('max', row),
    },
  ]

  const themeName = theme.isDark ? 'grafana-dark' : ''
  createTableTheme()

  if (!http) {
    // TODO add spinner
    return null
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={http.value}
        theme={themeName}
        highlightOnHover={true}
        pointerOnHover={true}
        expandableRowsHideExpander
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={HttpChart as any}
        expandableRowsComponentProps={{ ds, run }}
      />
    </div>
  )
}

function getStatus(expected: boolean): string {
  if (expected) {
    return '#28a745'
  }
  return '#dc3545'
}
