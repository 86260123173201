import React from 'react'
import { Icon, Tooltip, useTheme2 } from '@grafana/ui'
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router-dom'

import { InfiniteScroll } from 'components/InfiniteScroll'
import { createTableTheme } from '../styles'
import { TestRun, RUN_PROCESS } from 'types'
import { getTestStatusText } from 'pages/utils'
import { capitalizeFirstLetter } from '../../utils'
import { CenteredSpinner } from 'components/CenteredSpinner'

const columns = [
  {
    name: 'Status',
    selector: (row: TestRun) => getStatusColumnData(row),
  },
  {
    name: 'Config',
    selector: (row: TestRun) => getConfig(row),
  },
  {
    name: 'Load distribution',
    selector: (row: TestRun) => getDistribution(row),
  },
  {
    name: 'Started',
    selector: (row: TestRun) =>
      row.started ? new Date(row.started).toLocaleDateString() + ' ' + new Date(row.started).toLocaleTimeString() : '',
  },
  {
    name: 'Response Time',
    selector: (row: TestRun) => `${row.load_time ?? ' - '} ms`,
  },
  {
    name: 'Note',
    selector: (row: TestRun) => row.note,
  },
]

const getStatusColumnData = (testRun: TestRun) => {
  const statusText = getTestStatusText(testRun)
  const statusIconCSS = { display: 'inline-flex', alignItems: 'center' }

  if (testRun.is_baseline) {
    return (
      <>
        <span style={statusIconCSS}>
          <Tooltip content={'Baseline'} placement={'bottom-start'}>
            <Icon name="star" />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          {statusText}
        </span>
      </>
    )
  } else if (testRun.delete_status === 1) {
    return (
      <>
        <span style={statusIconCSS}>
          <Tooltip content={'Saved test result'} placement={'bottom'}>
            <Icon name="lock" />
          </Tooltip>
          <span style={{ marginLeft: '10px' }}>{statusText}</span>
        </span>
      </>
    )
  }

  return (
    <>
      <span style={{ marginLeft: '26px' }}>{statusText}</span>
    </>
  )
}

function getConfig(row: TestRun) {
  const vus = `${row.vus} VUS`

  if (row.duration === -1) {
    return vus
  }

  const date = new Date(row.duration * 1000)
  return `${vus}, ${date.getMinutes()} min ${date.getSeconds()}s`
}

function getDistribution(row: TestRun) {
  if (row.distribution && row.distribution.length > 0) {
    return row.distribution.reduce(
      (prevValue, curValue, index, arr) =>
        prevValue +
        capitalizeFirstLetter(curValue[0].split(':')[2]) +
        ' ' +
        curValue[1] +
        '%' +
        (index < arr.length - 1 ? ', ' : ''),
      ''
    )
  }

  return row.run_process === RUN_PROCESS.K6_INGEST ? 'Local Execution' : '-'
}

type RunsTableProps = {
  runs: TestRun[]
  isLoading: boolean
  isLoadingInitial: boolean
  loadNext: () => void
}

export const RunsTable = ({ runs, isLoading, isLoadingInitial, loadNext }: RunsTableProps) => {
  const history = useHistory()
  const theme = useTheme2()
  createTableTheme()
  const themeName = theme.isDark ? 'grafana-dark' : ''

  const onRowClick = (row: TestRun) => {
    history.push(`/runs/${row.id}`)
  }

  if (isLoadingInitial) {
    return <CenteredSpinner $height="100px" />
  }

  return (
    <InfiniteScroll isLoading={isLoading} loadNext={loadNext}>
      <DataTable
        columns={columns}
        data={runs}
        theme={themeName}
        onRowClicked={onRowClick}
        highlightOnHover={true}
        pointerOnHover={true}
      />
    </InfiniteScroll>
  )
}
