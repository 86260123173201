import { css } from 'emotion'
import { testIsDone, getTestRunColorString, getTestStatusText, testIsAborted } from 'pages/utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { BadgeColorCodes, TestRun } from 'types'

import { getDuration } from 'utils'
import { Badge } from '@grafana/ui'
import { BadgeColor } from '@grafana/ui/components/Badge/Badge'

interface Props {
  testRuns: TestRun[]
}

interface RunDetailsProps {
  vus: number
  duration: number
  loadTime: number
}

const smallText = css`
  font-size: 13px;
`
const dim = css`
  opacity: 0.7;
`

const styles = {
  badgeLink: css`
    &:hover {
      filter: brightness(1.3);
    }
  `,
  title: css`
    span {
      opacity: 0.7;
      margin-right: 10px;
    }
    time {
      ${smallText};
      ${dim};
    }
    margin-bottom: 5px;
  `,

  meta: css`
    display: flex;
    flex: 1 1 0;
    ${smallText};

    > div {
      align-self: center;
    }

    > div:not(:first-child) {
      border-left: 1px rgba(255, 255, 255, 0.1) solid;
      padding: 0 16px;
      margin-left: 15px;
    }

    .dim {
      ${dim};
    }
    .no-wrap {
      white-space: nowrap;
    }
  `,
}

function RunDetails({ vus, duration, loadTime }: RunDetailsProps) {
  if ((vus && duration > 0) || loadTime) {
    return (
      <div className={styles.meta}>
        <div>
          <span className="no-wrap">
            {vus} <span>VUs</span>
          </span>
          &nbsp;<span className="dim">in</span>&nbsp;
          <span className="no-wrap">{getDuration(duration)}</span>
        </div>
        {!!loadTime && (
          <div>
            <span className="dim">P95</span> <span className="no-wrap">{Math.round(loadTime || 0)} ms</span>
          </div>
        )}
      </div>
    )
  }

  return null
}

function DateTime({ timestamp }: { timestamp: string }) {
  if (timestamp) {
    const date = new Date(timestamp)
    return <time>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</time>
  }

  return null
}

function StatusLink({ run }: { run: TestRun }) {
  const color = getTestRunColorString(run)

  return (
    // stopPropagation in order to prevent the TestCard onClick to run
    <Link
      className={styles.badgeLink}
      style={{ color: BadgeColorCodes[color] }}
      onClick={(e) => e.stopPropagation()}
      to={`/runs/${run.id}`}
    >
      <Badge color={getTestRunColorString(run) as BadgeColor} text={getTestStatusText(run)} />
    </Link>
  )
}

export function LastRunMeta({ testRuns }: Props) {
  const mostRecentRun = testRuns.slice(-1)[0]

  const title = (
    <p className={styles.title}>
      <span>{testRuns.length === 0 ? 'No test runs' : 'LAST RUN'}</span>
      {mostRecentRun && <DateTime timestamp={mostRecentRun.created} />}
    </p>
  )

  if (testRuns.length === 0) {
    return title
  }

  const showStatusLink = testIsAborted(mostRecentRun.run_status) || !testIsDone(mostRecentRun)

  return (
    <>
      {title}
      <div>
        {showStatusLink ? (
          <StatusLink run={mostRecentRun} />
        ) : (
          <RunDetails vus={mostRecentRun.vus} duration={mostRecentRun.duration} loadTime={mostRecentRun.load_time} />
        )}
      </div>
    </>
  )
}
