export const testRunPanel = {
  id: 23763571993,
  gridPos: {
    h: 5,
    w: 12,
    x: 0,
    y: 5,
  },
  type: 'timeseries',
  title: 'Test Run',
  datasource: 'K6 Staging',
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: 'line',
        lineInterpolation: 'linear',
        barAlignment: 0,
        lineWidth: 1,
        fillOpacity: 0,
        gradientMode: 'none',
        spanNulls: false,
        showPoints: 'auto',
        pointSize: 5,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        scaleDistribution: {
          type: 'linear',
        },
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
      },
      color: {
        mode: 'palette-classic',
      },
      thresholds: {
        mode: 'absolute',
        steps: [
          {
            color: 'green',
            value: null,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
      mappings: [],
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'rps(http_reqs)',
        },
        properties: [
          {
            id: 'unit',
            value: 'reqps',
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'avg(http_req_duration)',
        },
        properties: [
          {
            id: 'unit',
            value: 'ms',
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'value(vus)',
        },
        properties: [
          {
            id: 'unit',
            value: 'VUs',
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'nz_rps(http_req_failed)',
        },
        properties: [
          {
            id: 'unit',
            value: 'reqps',
          },
        ],
      },
    ],
  },
  options: {
    tooltip: {
      mode: 'multi',
    },
    legend: {
      displayMode: 'list',
      placement: 'bottom',
      calcs: [],
    },
  },
  targets: [
    {
      refId: 'A',
      hide: false,
      qtype: 'metric',
      queryType: 'metric',
      projectId: 1283,
      testId: 3185,
      testRunId: 135247,
      metric: 'vus',
      uid: '01c96485888dde4185c080a747e9272e',
      aggregation: 'value',
    },
    {
      aggregation: 'rps',
      metric: 'http_reqs',
      projectId: 1283,
      qtype: 'metric',
      queryType: 'metric',
      refId: 'B',
      testId: 3185,
      testRunId: 135247,
      uid: '0206f0a22d574a1fcc535194bd89717d',
    },
    {
      refId: 'C',
      hide: false,
      qtype: 'metric',
      queryType: 'metric',
      projectId: 1283,
      testId: 3185,
      testRunId: 135247,
      metric: 'http_req_duration',
      uid: '01c96485888dde4185c080a747e9272e',
      aggregation: '0.95',
    },
    {
      refId: 'D',
      hide: false,
      qtype: 'metric',
      queryType: 'metric',
      projectId: 1283,
      testId: 3185,
      testRunId: 135247,
      metric: 'http_req_failed',
      uid: '01c96485888dde4185c080a747e9272e',
      aggregation: 'nz_rps',
    },
  ],
}
