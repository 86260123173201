import { useDatasource } from 'datasourceContext'
import { TestRun } from 'types'
import { useFetch } from './useFetch'

export const useSummary = (testRun: TestRun) => {
  const { ds } = useDatasource()

  return useFetch([testRun, 'summary'], (testRun) => ds.fetchSummary(testRun.id), {
    useStickyData: true,
  })
}
