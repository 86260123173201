// Libraries
import React, { PureComponent } from 'react'

// Types
import { PluginConfigPageProps, AppPluginMeta } from '@grafana/data'
import { K6AppSettings } from 'types'
import { Alert, Button, LoadingPlaceholder } from '@grafana/ui'

interface Props extends PluginConfigPageProps<AppPluginMeta<K6AppSettings>> {}

interface State {
  enabled: boolean
  loadedSettings: boolean
  responseStatus: number
  responseOk: boolean
  disableBtn: boolean
}

const SETTINGS_ENDPOINT = '/api/plugins/grafana-k6-app/settings'

export class ConfigurationPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      enabled: false,
      responseOk: true,
      loadedSettings: false,
      responseStatus: 0,
      disableBtn: false,
    }
  }

  async componentDidMount() {
    const response = await fetch(SETTINGS_ENDPOINT)

    if (!response.ok) {
      this.setState({
        responseOk: response.ok,
        responseStatus: response.status,
        loadedSettings: true,
      })

      return
    }

    const json = await response.json()
    if (json) {
      this.setState({
        enabled: json.enabled,
        loadedSettings: true,
      })
    }
  }

  togglePlugin = async () => {
    this.setState({
      disableBtn: true,
    })

    const body = {
      enabled: !this.state.enabled,
      pinned: !this.state.enabled,
    }

    const response = await fetch(SETTINGS_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      this.setState({
        responseStatus: response.status,
        loadedSettings: true,
      })

      return
    }

    window.location.reload()
  }

  render() {
    const noMargin = { margin: 0 }

    return (
      <>
        {!this.state.responseOk && (
          <div>
            <Alert title={`${this.state.responseStatus} Unexpected error occured`} severity={'error'} />
          </div>
        )}
        <div>
          <Button onClick={this.togglePlugin} disabled={!this.state.responseOk || this.state.disableBtn}>
            {!this.state.loadedSettings ? (
              <LoadingPlaceholder text="Loading..." style={noMargin} />
            ) : this.state.enabled ? (
              'Disable plugin'
            ) : (
              'Enable plugin'
            )}
          </Button>
        </div>
      </>
    )
  }
}
