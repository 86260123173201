import { SelectableValue } from '@grafana/data'
import { Button, InlineFormLabel, Modal, Select } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { useHistory } from 'react-router-dom'

import { Org, Project, Awaited } from 'types'
import { styles } from 'pages/styles'
import React, { useEffect, useState } from 'react'
import { useAppContext } from 'appContext'
import { useDatasource } from 'datasourceContext'

interface Props {
  base: string
  dismiss: () => void
}

export const SettingsModal = (props: Props) => {
  const { dismiss } = props
  const appContext = useAppContext()
  const { ds: appDs, getDsList, setActiveDsId } = useDatasource()
  const [dsId, setDSId] = useState<number>(appDs.id)
  const [ds, setDs] = useState(appDs)
  const [orgs, setOrgs] = useState<SelectableValue[]>([])
  const [projects, setProjects] = useState<SelectableValue[]>([])
  const [orgId, setOrgId] = useState<number | undefined>(appContext.orgId)
  const [projectId, setProjectId] = useState<number | undefined>(appContext.projectId)
  const [dsList, setDsList] = useState<Awaited<ReturnType<typeof getDsList>>>([])
  const history = useHistory()

  useEffect(() => {
    fetchOrgs(ds)
  }, [ds])

  useEffect(() => {
    fetchProjects(ds, orgId)
  }, [ds, orgId])

  useEffect(() => {
    getDsList().then(setDsList)
  }, [getDsList])

  useEffect(() => {
    if (dsList.length === 0) {
      return
    }

    const dsMatch = dsList.find((d) => d.id === dsId)
    setDs(dsMatch!)
  }, [dsId, dsList])

  const fetchOrgs = async (ds: K6DataSource) => {
    setOrgs([])
    const orgs = await ds.fetchOrgs()
    const options = orgs.map((r: Org) => ({ label: r.name, value: r.id }))
    setOrgs(options)
  }

  const fetchProjects = async (ds: K6DataSource, orgId?: number) => {
    setProjects([])
    if (!orgId) {
      return
    }
    const projects = await ds.fetchProjects(orgId)
    const options = projects.filter((project) => project.is_k6).map((r: Project) => ({ label: r.name, value: r.id }))
    setProjects(options)
  }

  const onDSChange = ({ value }: SelectableValue<number>) => {
    setOrgId(undefined)
    setProjectId(undefined)
    setDSId(value!)
  }

  const onOrgChange = ({ value }: SelectableValue<number>) => {
    setOrgId(value)
    setProjectId(undefined)
  }

  const onProjectChange = ({ value }: SelectableValue<number>) => {
    setProjectId(value!)
  }

  const onSave = () => {
    if (!projectId) {
      return
    }

    setActiveDsId(dsId)
    history.push(`/projects/${projectId}`)
    dismiss()
  }

  const dsOptions = dsList.map((d) => ({ label: d.name, value: d.id }))

  return (
    <Modal title={'Settings'} isOpen={true} onDismiss={dismiss} className={styles.settings}>
      {dsOptions.length > 1 && (
        <div className={styles.mb2 + ' gf-form'}>
          <InlineFormLabel width={8} className="query-keyword">
            Datasource
          </InlineFormLabel>
          <Select
            className="width-16"
            onChange={onDSChange}
            options={dsOptions}
            value={dsId}
            menuPlacement={'auto'}
            menuShouldPortal={true}
          ></Select>
        </div>
      )}
      <div className={styles.mb2 + ' gf-form'}>
        <InlineFormLabel width={8} className="query-keyword">
          Organization
        </InlineFormLabel>
        <Select
          className="width-16"
          onChange={onOrgChange}
          options={orgs}
          value={orgId ?? null}
          menuPlacement={'auto'}
          menuShouldPortal={true}
        ></Select>
      </div>
      <div className={styles.mb2 + ' gf-form'}>
        <InlineFormLabel width={8} className={'query-keyword'}>
          Project
        </InlineFormLabel>
        <Select
          className="width-16"
          onChange={onProjectChange}
          options={projects}
          value={projectId ?? null}
          menuPlacement={'auto'}
          menuShouldPortal={true}
        ></Select>
      </div>
      <Modal.ButtonRow>
        <Button onClick={onSave}>OK</Button>
      </Modal.ButtonRow>
    </Modal>
  )
}
