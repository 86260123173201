import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'
import { useFetch } from './useFetch'

export const useOrgRoles = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useFetch(
    () => (orgId ? [orgId, 'orgRoles'] : null),
    (orgId) => ds.fetchOrgRoles(orgId)
  )
}
