import { useState, useEffect } from 'react'
import useSWR, { Key, Fetcher, SWRConfiguration } from 'swr'
import useSWRInfinite, { SWRInfiniteKeyLoader, SWRInfiniteFetcher, SWRInfiniteConfiguration } from 'swr/infinite'

import { stickyData } from 'utils/swrStickyMiddleware'
import { sentryClient } from '../sentryClient'

type Config = {
  useStickyData?: boolean
}

export const useFetch = <Data, SWRKey extends Key>(
  key: SWRKey,
  fetcher: Fetcher<Data, SWRKey>,
  config?: SWRConfiguration<Data> & Config
) => {
  const { useStickyData, ...swrConfig } = config || {}
  const { error, ...swrResponse } = useSWR(key, fetcher, {
    use: useStickyData ? [stickyData] : [],
    ...swrConfig,
  })

  if (error) {
    sentryClient.setContext('APIResponse', error)
    throw new FetchError('Failed to fetch')
  }

  return swrResponse
}

export const useFetchPaginated = <Data, KeyLoader extends SWRInfiniteKeyLoader>(
  key: KeyLoader,
  fetcher: SWRInfiniteFetcher<Data, KeyLoader>,
  config?: SWRInfiniteConfiguration<Data>
) => {
  const [isLoadingNext, setIsLoadingNext] = useState(false)

  const { error, setSize, data, ...swrResponse } = useSWRInfinite(key, fetcher, config)

  const loadNext = () => {
    setSize((size) => size + 1)
    setIsLoadingNext(true)
  }

  useEffect(() => {
    setIsLoadingNext(false)
  }, [data])

  if (error) {
    sentryClient.setContext('APIResponse', error)
    throw new FetchError('Failed to fetch')
  }

  return {
    ...swrResponse,
    data,
    loadNext,
    isLoading: !data || isLoadingNext,
    // First batch of data is being loaded
    isLoadingInitial: !data,
  }
}

class FetchError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, FetchError.prototype)
    this.name = 'FetchError'
  }
}
