import React from 'react'
import { Badge, BadgeColor, HorizontalGroup, Tooltip } from '@grafana/ui'
import styled from 'styled-components'

import { TestRun, RUN_PROCESS } from 'types'
import { getDuration, testIsSafe } from 'utils'
import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { getTestRunColorString, getTestRunBadgeIcon, getTestStatusText } from '../../utils'
import { MetaItem } from './MetaItem'
import { vusFormmatter } from 'utils/formatters'
import { LoadZones } from './LoadZones'
import { StartedBy } from './StartedBy'

const Wrapper = styled.div`
  padding-bottom: 20px;
`

const Items = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
`

const safeTooltipText = 'Result will not be deleted by data retention policy'

const LoadZoneOrLocal = ({ testRun }: { testRun: TestRun }) => {
  if (testRun.run_process === RUN_PROCESS.K6_INGEST) {
    return <MetaItem icon="arrow">Local execution</MetaItem>
  }

  return (
    <MetaItem icon="database">
      Load zones:
      <LoadZones nodes={testRun.nodes} distribution={testRun.distribution} />
    </MetaItem>
  )
}

export const MetaBar = ({ testRun }: { testRun: TestRun }) => {
  const statusText = getTestStatusText(testRun)
  const statusColor = getTestRunColorString(testRun)
  const statusIcon = getTestRunBadgeIcon(testRun)
  const isBaselineTest = testRun.is_baseline
  const canShowNote = testRun.note && testRun.note.length > 0

  return (
    <Wrapper>
      <HorizontalGroup justify={'space-between'}>
        <Items>
          <Badge color={statusColor as BadgeColor} icon={statusIcon} text={statusText} />
          <MetaItem icon="clock-nine">{getDuration(testRun.duration)}</MetaItem>
          <MetaItem icon="users-alt">{vusFormmatter(testRun.vus)}</MetaItem>
          <LoadZoneOrLocal testRun={testRun} />
          <MetaItem icon="user">
            <StartedBy userId={testRun.user_id} />
          </MetaItem>
          {isBaselineTest && <MetaItem icon="star">Baseline run</MetaItem>}

          {testIsSafe(testRun) && (
            <MetaItem icon="lock">
              <Tooltip content={safeTooltipText} placement="bottom">
                <span>Safe</span>
              </Tooltip>
            </MetaItem>
          )}

          {canShowNote && (
            <MetaItem icon="edit">
              <Tooltip content={testRun.note} placement="bottom">
                <span>Note</span>
              </Tooltip>
            </MetaItem>
          )}
        </Items>
        <HorizontalGroup>
          <GoToK6Button type={ButtonTypes.Runs} id={testRun.id}></GoToK6Button>
        </HorizontalGroup>
      </HorizontalGroup>
    </Wrapper>
  )
}
