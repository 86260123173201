import React from 'react'
import { Alert, VerticalGroup } from '@grafana/ui'

export const MissingDatasource = () => {
  const handleClick = () => {
    window.location.href = '/datasources/new'
  }

  return (
    <div style={{ margin: '20px' }}>
      <Alert title={'Missing Datasource'} severity={'error'} buttonContent={<span>{'Go'}</span>} onRemove={handleClick}>
        <VerticalGroup>
          <div>You must add a K6 Datasource.</div>
        </VerticalGroup>
      </Alert>
    </div>
  )
}
