import { useFetch } from './useFetch'
import { useDatasource } from 'datasourceContext'

const getKey = (id: number | (() => number)) => {
  if (typeof id === 'function') {
    return id()
  }
  return id
}

export const useTest = (id: number | (() => number)) => {
  const { ds } = useDatasource()

  return useFetch(() => [getKey(id), 'test'], ds.fetchTest.bind(ds))
}
