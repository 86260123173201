import React from 'react'
import { css } from 'emotion'

import {
  DataFrame,
  dateTime,
  FieldConfigSource,
  GrafanaTheme2,
  KeyValue,
  LoadingState,
  PanelData,
  TimeRange,
} from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { CardContainer, PanelChrome, Tag, ToolbarButton, useStyles2 } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { styles } from 'pages/styles'
import {
  formatRunsForDataFrame,
  getTestRunColor,
  getTestRunColorString,
  loadFormattedRunsToDataframes,
  padEmptyBars,
} from 'pages/utils'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TestRun, Test } from 'types'
import { LastRunMeta } from './LastRunMeta'
import { MAX_VALUE_EMPTY_BARS } from '../constants'

interface Props {
  ds: K6DataSource
  test: Test
  run: Function
  open: Function
}

export const TestCard = (props: Props) => {
  const testRuns = props.test.test_runs
  const isRefreshing = false
  const onCancelQuery = () => {}
  const noop = () => {}
  const testCardStyles = useStyles2(getStyles)
  const sortedRuns = getSortedTestRuns(testRuns)
  let statusColor = styles.Common.grey

  if (sortedRuns.length > 0) {
    statusColor = getTestRunColor(sortedRuns[sortedRuns.length - 1])
  }

  const { panelData, panelOptions, overrides, fieldConfig } = getPanelData(sortedRuns)

  return (
    <>
      <CardContainer key={props.test.id} onClick={() => props.open(props.test)}>
        <div className={testCardStyles.testCardBody}>
          <header>
            <div className={statusColor}>
              <Tag name="" onClick={() => props.open(props.test)} />
            </div>
            <h5>{props.test.name}</h5>
            <div>
              <ToolbarButton icon="play" tooltip="Start test" onClick={(e) => props.run(e, props.test)} />
            </div>
          </header>

          <section className="last-run-meta">
            <LastRunMeta testRuns={sortedRuns} />
          </section>

          <section className="trending">
            <AutoSizer disableHeight>
              {(size) => {
                return (
                  <PanelChrome
                    width={size.width}
                    height={100}
                    leftItems={[
                      <PanelChrome.LoadingIndicator
                        loading={isRefreshing}
                        onCancel={onCancelQuery}
                        key="loading-indicator"
                      />,
                    ]}
                  >
                    {(innerWidth, innerHeight) => (
                      <PanelRenderer
                        title={'Trending p95 response time'}
                        pluginId="bargauge"
                        onOptionsChange={noop}
                        width={innerWidth}
                        height={innerHeight}
                        data={panelData}
                        options={panelOptions}
                        fieldConfig={{ ...fieldConfig, overrides } as FieldConfigSource}
                      />
                    )}
                  </PanelChrome>
                )
              }}
            </AutoSizer>
          </section>
        </div>
      </CardContainer>
    </>
  )
}

export const getStyles = (_theme: GrafanaTheme2) => {
  return {
    testCardBody: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h5 {
          margin-left: 10px;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 11px;
          flex: 1;
        }
        > div:first-child button {
          height: 16px;
          width: 16px;
          display: inline-block;
          vertical-align: text-bottom;
        }
      }

      .last-run-meta {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        margin-bottom: 10px;
      }

      .trending {
        min-height: 100px;
      }
    `,
  }
}

export const getSortedTestRuns = (testRuns: TestRun[]) => {
  return [...testRuns].sort((a, b) => {
    return new Date(a.created).getTime() - new Date(b.created).getTime()
  })
}

export const getPanelData = (sortedRuns: TestRun[]) => {
  const MAX_BARS = 20
  let maxValue = 0

  const runsWithData = sortedRuns
    .map((run) => {
      maxValue = Math.max(maxValue, run.load_time)

      if (run.load_time === null) {
        return { ...run, load_time: 0 }
      }

      return run
    })
    .reverse()

  if (maxValue === 0) {
    maxValue = MAX_VALUE_EMPTY_BARS
  }

  let runsToPlot = runsWithData
  if (runsWithData.length < MAX_BARS) {
    runsToPlot = padEmptyBars(runsWithData, MAX_BARS - runsWithData.length)
  }

  const rto = runsToPlot[0]?.created
  const rfrom = runsToPlot[runsToPlot.length - 1]?.created
  const range: TimeRange = {
    from: dateTime(rfrom),
    to: dateTime(rto),
    raw: { from: rfrom, to: rto },
  }

  const formattedRuns = formatRunsForDataFrame(runsToPlot)
  const frames: DataFrame[] = loadFormattedRunsToDataframes(formattedRuns)

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: frames,
    timeRange: range,
  }

  const panelOptions = getPanelOptions()
  const overrides = getOverrides(formattedRuns)
  const fieldConfig = getFieldConfig(maxValue)

  return { panelData, panelOptions, overrides, fieldConfig }
}

const getPanelOptions = () => {
  return {
    displayMode: 'gradient',
    orientation: 'auto',
    reduceOptions: {
      calcs: ['lastNotNull'],
      fields: '',
      limit: 1000,
      values: true,
    },
    showUnfilled: true,
    text: {
      titleSize: 0,
      valueSize: 0,
    },
  }
}

const getOverrides = (formattedRuns: KeyValue) => {
  return Object.entries(formattedRuns).map((run) => {
    const statusColor = getTestRunColorString(run[1].status)
    return {
      matcher: {
        id: 'byFrameRefID',
        options: run[0],
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: statusColor,
            mode: 'fixed',
          },
        },
      ],
    }
  })
}

const getFieldConfig = (maxValue: number) => {
  return {
    defaults: {
      color: {
        mode: 'thresholds',
      },
      mappings: [],
      min: 0,
      max: maxValue,
      thresholds: {
        mode: 'absolute',
        steps: [
          {
            color: 'green',
            value: null,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'failed',
        },
        properties: [
          {
            id: 'color',
            value: {
              mode: 'fixed',
              fixedColor: 'red',
            },
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'passed',
        },
        properties: [
          {
            id: 'color',
            value: {
              mode: 'palette-classic',
            },
          },
          {
            id: 'color',
            value: {
              mode: 'fixed',
              fixedColor: 'green',
            },
          },
        ],
      },
    ],
  }
}
