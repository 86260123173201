import { Alert, IconButton } from '@grafana/ui'
import { styles } from 'pages/styles'
import React, { useState } from 'react'
import { QueryType, TestRun } from 'types'
import { copyToClipboard } from 'utils'
import { useTrackFeature } from 'hooks/useTrackFeature'

interface Props {
  name: string
  queryType: QueryType
  testRun: TestRun
  itemId?: string
  itemName?: string
  itemValue?: number
}

export const ClipBoard = (props: Props) => {
  const [alert, setAlert] = useState<boolean>(false)
  const trackFeature = useTrackFeature()

  const copyChartToClipboard = () => {
    const { name, queryType, testRun, itemId, itemName, itemValue } = props
    copyToClipboard(name, queryType, testRun, itemId, itemName, itemValue)
    setAlert(true)
    setTimeout(() => {
      setAlert(false)
    }, 3000)
    trackFeature('copy_to_clipboard')
  }

  const removeAlert = () => {
    setAlert(false)
  }

  return (
    <>
      <IconButton name="clipboard-alt" onClick={copyChartToClipboard} className={styles.copyButton} size="xl">
        Copy to Clipboard
      </IconButton>
      {alert && (
        <Alert title={`Copied to Clipboard!`} severity={'success'} onRemove={removeAlert} className={styles.alert} />
      )}
    </>
  )
}
