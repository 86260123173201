import {
  ArrayDataFrame,
  dateTime,
  FieldConfig,
  LoadingState,
  PanelData,
  ThresholdsMode,
  TimeRange,
} from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { LegendDisplayMode, PanelChrome } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import React, { useState, useEffect } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { QueryType, TestRun } from 'types'
import { ClipBoard } from './Clipboard'

interface Props {
  data: any
  ds: K6DataSource
  run: TestRun
}

export const ThresholdChart = (props: Props) => {
  const { run, ds, data } = props
  const dataId = data.id
  const isRefreshing = false
  const onCancelQuery = () => {}
  const noop = () => {}

  const [chartData, setChartData] = useState<any>({ series: [] })

  useEffect(() => {
    async function load(ds: K6DataSource) {
      const response: any = await ds.fetchTimeSeries('thresholds', run.id, '', '', dataId)

      const info = response.value[0]

      if (!info) {
        return
      }

      let rangeValues: any[] = []
      const values: any[] = response.value[0]?.values || []
      if (values.length > 0) {
        rangeValues = values
      }
      const label = info.metric_name
      const series = values.map((v: any) => ({
        timestamp: new Date(v.timestamp),
        [label]: v.value,
      }))

      const from = rangeValues[0].timestamp
      const to = rangeValues[rangeValues.length - 1].timestamp
      const r: TimeRange = {
        from: dateTime(from),
        to: dateTime(to),
        raw: { from, to },
      }

      const chartData = {
        series: series,
        range: r,
        info: info,
      }
      setChartData(chartData)
    }
    load(ds)
  }, [ds, run, dataId])

  const panelOptions = {
    legend: {
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
      calcs: [],
    },
    graph: {},
    tooltipOptions: {
      mode: 'single',
    },
  }

  const frame = new ArrayDataFrame(chartData.series || [])
  if (frame.fields && frame.fields.length > 0) {
    if (chartData.info.metric_name?.includes('duration')) {
      const cfg: FieldConfig = { unit: 'ms' }
      frame.fields[1].config = cfg
    }
  }

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: [frame],
    timeRange: chartData.range,
  }

  const fieldConfig = {
    defaults: {
      custom: {
        spanNulls: true,
        thresholdsStyle: {
          mode: 'line',
        },
      },
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            value: null,
            color: 'green',
          },
          {
            value: data.value,
            color: 'red',
          },
        ],
      },
    },
    overrides: [],
  }

  return (
    <div>
      <ClipBoard
        name={ds.name}
        queryType={QueryType.THRESHOLDS}
        testRun={run}
        itemId={dataId?.toString()}
        itemName={data?.name}
        itemValue={data?.value}
      />
      <AutoSizer disableHeight>
        {(size) => {
          return (
            <PanelChrome
              width={size.width}
              height={300}
              leftItems={[
                <PanelChrome.LoadingIndicator
                  loading={isRefreshing}
                  onCancel={onCancelQuery}
                  key="loading-indicator"
                />,
              ]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title=""
                  pluginId="timeseries"
                  onOptionsChange={noop}
                  // onChangeTimeRange={(t) => onChangeTimeRange(toTimeRange(t, timeZone))}
                  width={innerWidth}
                  height={innerHeight}
                  // timeZone={timeZone}
                  data={panelData}
                  options={panelOptions}
                  fieldConfig={fieldConfig}
                />
              )}
            </PanelChrome>
          )
        }}
      </AutoSizer>
    </div>
  )
}
