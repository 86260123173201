import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { Flag } from 'components/Flag'

const StyledLabel = styled.div`
  display: flex;
  margin-left: 5px;
`

const StyledFlag = styled.div`
  height: 15px;
`

const Item = styled.div`
  margin-right: 5px;
`

type Props = {
  country: string
  city: string
  loadPercent: number
  isPublic: boolean
  className?: string
  showPrivateIcon?: boolean
}

export const LoadZoneLabel = ({ country, city, loadPercent, isPublic, className, showPrivateIcon }: Props) => {
  return (
    <StyledLabel className={className}>
      <Item>
        <StyledFlag>
          <Flag name={country.toLowerCase()} />
        </StyledFlag>
      </Item>
      {!isPublic && showPrivateIcon && (
        <Item>
          <Icon name="eye-slash" />
        </Item>
      )}
      <Item>{city}</Item>
      {loadPercent !== 100 && <Item>{loadPercent}%</Item>}
    </StyledLabel>
  )
}
