export const thresholdPanel = {
  id: 23763571993,
  gridPos: {
    x: 0,
    y: 0,
    w: 12,
    h: 8,
  },
  type: 'timeseries',
  title: 'Thresholds',
  options: {
    tooltip: {
      mode: 'single',
    },
    legend: {
      displayMode: 'list',
      placement: 'bottom',
      calcs: [],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: 'line',
        lineInterpolation: 'linear',
        barAlignment: 0,
        lineWidth: 1,
        fillOpacity: 0,
        gradientMode: 'none',
        spanNulls: false,
        showPoints: 'auto',
        pointSize: 5,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        scaleDistribution: {
          type: 'linear',
        },
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'line',
        },
      },
      color: {
        mode: 'palette-classic',
      },
      thresholds: {
        mode: 'absolute',
        steps: [
          {
            value: null,
            color: 'green',
          },
          {
            value: 10000,
            color: 'red',
          },
        ],
      },
      mappings: [],
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'Value',
        },
        properties: [
          {
            id: 'displayName',
            value: 'Value',
          },
        ],
      },
    ],
  },
  datasource: 'K6 Staging',
  targets: [
    {
      refId: 'A',
      qtype: 'thresholds',
      queryType: 'thresholds',
      projectId: 1283,
      testId: 3185,
      testRunId: 135315,
      uid: '49696',
    },
  ],
}
