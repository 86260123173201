import { useTheme2 } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { createTableTheme } from 'pages/styles'
import React from 'react'
import DataTable from 'react-data-table-component'
import { TestRun } from 'types'
import { ThresholdChart } from './ThresholdChart'
import { useThresholds } from 'data/useThresholds'

interface Props {
  run: TestRun
  ds: K6DataSource
}

export const ThresholdTab = ({ ds, run }: Props) => {
  const { data: thresholds } = useThresholds(run)

  const theme = useTheme2()

  const Status = (tainted: any) => {
    return <div style={{ backgroundColor: getStatus(tainted.status), height: '44px' }}>&nbsp;</div>
  }

  const columns = [
    {
      name: '',
      // eslint-disable-next-line react/display-name
      selector: (row: any) => <Status status={row.tainted}></Status>,
      width: '4px',
      compact: true,
    },
    {
      name: '',
      selector: (row: any) => row.name,
    },
    {
      name: '',
      selector: ({ calculated_value, stat }: any) => `${stat} = ${calculated_value?.toFixed(2) ?? '-'}`,
    },
  ]

  const themeName = theme.isDark ? 'grafana-dark' : ''
  createTableTheme()

  if (!thresholds) {
    // TODO add spinner
    return null
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={thresholds.value}
        theme={themeName}
        highlightOnHover={true}
        pointerOnHover={true}
        expandableRowsHideExpander
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={ThresholdChart as any}
        expandableRowsComponentProps={{ ds, run }}
        noTableHead
      />
    </div>
  )
}

function getStatus(tainted: boolean): string {
  if (!tainted) {
    return '#28a745'
  }
  return '#dc3545'
}
