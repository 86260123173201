export const toPrecise = (val: number, precision = 2) => Number(Number(val).toFixed(precision))

export const responseTimeFormatter = (responseTimeMS: number) => {
  const precision = responseTimeMS > 10 ? 0 : 2
  const fixedNumber = Number(Number(responseTimeMS).toFixed(precision))
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const separatorFormatter = (num: number, precision = 0, delimiter = ' ') => {
  return String(toPrecise(num, precision)).replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

export const vusFormmatter = (vus: number) => {
  if (vus > 0) {
    if (vus === 1) {
      return `${vus} VU`
    }

    return `${separatorFormatter(vus)} VUs`
  }

  return `-`
}
