import React, { ReactNode } from 'react'
import { IconButton } from '@grafana/ui'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { styles } from 'pages/styles'
import { Logo } from 'components/Logo'

type LayoutProps = {
  children: ReactNode
  onShowSettings: () => void
}

export const Layout = ({ children, onShowSettings }: LayoutProps) => {
  return (
    <>
      <div className={'page-container ' + styles.crumbs}>
        <div style={{ display: 'flex' }}>
          <div>
            <Logo className={styles.logo} />
          </div>
          <div style={{ paddingTop: '4px' }}>
            <Breadcrumbs />
          </div>
        </div>
        <div className={styles.settingsButton}>
          <IconButton name="cog" onClick={onShowSettings}></IconButton>
        </div>
      </div>
      <div className="page-container page-body">{children}</div>
    </>
  )
}
