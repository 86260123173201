import { useAppContext } from 'appContext'
import { useDatasource } from 'datasourceContext'
import { sentryClient } from 'sentryClient'

export const useTrackFeature = () => {
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  const trackFeature = (action: string) => {
    if (!orgId) {
      sentryClient.captureException(new Error('missing organization id'))
      return
    }

    return ds.trackFeature({
      feature: 'grafana_k6_app_plugin',
      organization_id: orgId,
      additional_data: {
        action,
      },
    })
  }

  return trackFeature
}
