import { useDatasource } from 'datasourceContext'
import { TestRun } from 'types'
import { useFetch } from './useFetch'

type MetricPayload = {
  method: string
  metric: string
  unit: string
  label: string
  type: string
  id: number
}

export const useTimeSeries = (testRun: TestRun, types: MetricPayload[]) => {
  const { ds } = useDatasource()

  const fetchSeries = (runId: number) => {
    return Promise.all(types.map((t) => ds.fetchTimeSeries(t.type, runId, t.metric, t.method)))
  }

  return useFetch([testRun, 'series', types], (testRun) => fetchSeries(testRun.id), {
    useStickyData: true,
  })
}
