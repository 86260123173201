import { uniq } from 'lodash-es'
import { useState, useEffect } from 'react'

import { useFetch } from './useFetch'
import { BackgroundTaskType, TestRun } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'
import { testIsActive } from 'pages/utils'
import { BG_TASKS_POLL_INTERVAL } from '../constants'

const fetchActiveRuns = (ds: K6DataSource, activeRuns: TestRun[]) => async (projectId: number) => {
  const bgTasks = await ds.fetchBackgroundTasks(projectId)
  const tasks = bgTasks.filter((task) => task.type === BackgroundTaskType.TEST_RUN)

  // prettier-ignore
  const activeRunsIds = uniq([
    ...tasks.map((task) => task.test_run_id),
    ...activeRuns.map((run) => run.id)
  ])

  return Promise.all(activeRunsIds.map((id) => ds.fetchTestRun(id)))
}

export const useActiveRuns = () => {
  const { ds } = useDatasource()
  const { projectId } = useAppContext()
  const [activeRuns, setActiveRuns] = useState<TestRun[]>([])

  const { data, mutate } = useFetch([projectId, 'background-tasks'], fetchActiveRuns(ds, activeRuns), {
    refreshInterval: BG_TASKS_POLL_INTERVAL,
  })

  // We still need to update active runs when background tasks return []
  // to make sure tests are not stuck in "processing"
  const cacheActiveRuns = () => {
    if (!data) {
      return
    }

    setActiveRuns(data.filter(testIsActive))
  }

  useEffect(cacheActiveRuns, [data])

  return {
    data,
    refresh: mutate,
  }
}
