import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { testIsStarting } from '../utils'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { formatDate } from 'utils/formatDate'
import { useRun } from 'data/useRun'
import { useTest } from 'data/useTest'
import { RunSummary } from './RunSummary'
import { OverviewChart } from './OverviewChart'
import { Breakdown } from './Breakdown'
import { MetaBar } from './MetaBar/MetaBar'
import { CenteredSpinner } from 'components/CenteredSpinner'

export const RunDetails = () => {
  const { runId } = useParams<{ runId: string }>()
  const { project } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()

  const { data: testRun } = useRun(+runId)
  const { data: test } = useTest(() => testRun!.test_id)

  useEffect(() => {
    if (!project || !test || !testRun) {
      return
    }

    setBreadcrumbs([
      { path: `/projects/${project.id}`, name: project.name },
      { path: `/tests/${test.id}`, name: test.name },
      { path: `/runs/${testRun.id}`, name: formatDate(testRun.created) },
    ])
  }, [setBreadcrumbs, project, test, testRun])

  if (!testRun) {
    return <CenteredSpinner $height="300px" />
  }

  return (
    <>
      <MetaBar testRun={testRun} />
      {testIsStarting(testRun) && <h2>Setting up test!</h2>}

      {!testIsStarting(testRun) && (
        <>
          <RunSummary testRun={testRun} />
          <OverviewChart testRun={testRun} />
          <Breakdown testRun={testRun} />
        </>
      )}
    </>
  )
}
