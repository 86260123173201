export const checksPanel = {
  id: 23763571993,
  gridPos: {
    x: 0,
    y: 0,
    w: 12,
    h: 8,
  },
  type: 'timeseries',
  title: 'Checks',
  options: {
    tooltip: {
      mode: 'single',
    },
    legend: {
      displayMode: 'list',
      placement: 'bottom',
      calcs: [],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: 'bars',
        lineInterpolation: 'linear',
        barAlignment: 0,
        lineWidth: 7,
        fillOpacity: 100,
        gradientMode: 'none',
        spanNulls: true,
        showPoints: 'auto',
        pointSize: 12,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        scaleDistribution: {
          type: 'linear',
        },
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
      },
      color: {
        mode: 'palette-classic',
      },
      thresholds: {
        mode: 'absolute',
        steps: [
          {
            value: null,
            color: 'green',
          },
          {
            value: 80,
            color: 'red',
          },
        ],
      },
      mappings: [],
    },
    overrides: [],
  },
  datasource: 'K6 Staging',
  targets: [
    {
      refId: 'A',
      qtype: 'checks',
      queryType: 'checks',
      projectId: 1283,
      testId: 3185,
      testRunId: 135315,
      metric: 'passes',
      aggregation: 'count',
      uid: '01faf35e-9caf-5038-a3a1-3423b1b50d9e',
    },
    {
      refId: 'B',
      hide: false,
      qtype: 'checks',
      queryType: 'checks',
      projectId: 1283,
      testId: 3185,
      testRunId: 135315,
      metric: 'failures',
      aggregation: 'count',
      uid: '01faf35e-9caf-5038-a3a1-3423b1b50d9e',
    },
  ],
}
