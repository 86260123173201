import { LinkButton } from '@grafana/ui'
import React from 'react'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { ApplicationUrls } from '../constants'
import { useAppContext } from 'appContext'

interface Props {
  text?: string
  type: string
  id: number
}

export enum ButtonTypes {
  Projects = 'projects',
  Test = 'tests',
  Runs = 'runs',
}

export const GoToK6Button = (props: Props) => {
  const { text, type, id } = props
  const trackFeature = useTrackFeature()
  const { isProd } = useAppContext()

  const handleClick = () => {
    trackFeature('open_in_k6_cloud')
  }

  const k6Url = isProd ? ApplicationUrls.ProdUrl : ApplicationUrls.StgUrl

  return (
    <LinkButton variant={'secondary'} href={k6Url + type + '/' + id} target={'_blank'} onClick={handleClick}>
      {text ?? 'Open in k6 cloud'}
    </LinkButton>
  )
}
