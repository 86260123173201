import { AppRootProps } from '@grafana/data'
import React, { useState, createContext, SetStateAction } from 'react'
import { TestsPageState } from 'types'
import { BrowserRouter as Router } from 'react-router-dom'
import { SettingsModal } from 'components/SettingsModal'
import { AppContextProvider } from 'appContext'
import { SetupSentry } from 'components/SetupSentry'
import { Routes } from 'Routes'
import { Layout } from 'components/Layout'
import { DatasourceContextProvider } from 'datasourceContext'
import { BreadcrumbsContextProvider } from 'breadcrumbsContext'
import { ErrorBoundary } from 'components/ErrorBoundary'

const defaultState: React.Dispatch<SetStateAction<TestsPageState>> = {} as React.Dispatch<
  SetStateAction<TestsPageState>
>

export const TestsContext = createContext({
  state: {} as TestsPageState,
  setState: defaultState,
})

export const RootPage = React.memo(function RootPage(props: AppRootProps) {
  const [showSettings, setShowSettings] = useState<boolean>(false)

  let { basename } = props
  if (!basename.endsWith('/')) {
    basename += '/'
  }

  const onShowSettings = async () => {
    setShowSettings(true)
  }

  const onDismiss = () => {
    setShowSettings(false)
  }

  return (
    <Router basename={basename}>
      <ErrorBoundary>
        <DatasourceContextProvider>
          <AppContextProvider>
            <BreadcrumbsContextProvider>
              <SetupSentry pluginVersion={props.meta.info.version} />
              {showSettings && <SettingsModal base={basename} dismiss={onDismiss} />}
              <Layout onShowSettings={onShowSettings}>
                <Routes />
              </Layout>
            </BreadcrumbsContextProvider>
          </AppContextProvider>
        </DatasourceContextProvider>
      </ErrorBoundary>
    </Router>
  )
})
