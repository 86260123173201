import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { canRunTest, testIsActive } from '../utils'
import { Button, HorizontalGroup, Icon } from '@grafana/ui'
import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { useDatasource } from 'datasourceContext'
import { useRuns } from 'data/useRuns'
import { useTest } from 'data/useTest'
import { RunsTable } from './RunsTable'
import { TrendingChart } from './TrendingChart'

export const RunsPage = () => {
  const { testId } = useParams<{ testId: string }>()
  const trackFeature = useTrackFeature()
  const { project, setProjectId } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { ds } = useDatasource()
  const { runs, isLoading, isLoadingInitial, loadNext, refresh } = useRuns(+testId)
  const { data: K6Test } = useTest(+testId)

  const isRunActive = runs.some(testIsActive)

  useEffect(() => {
    if (!project || !K6Test) {
      return
    }

    setBreadcrumbs([
      { path: `/projects/${project.id}`, name: project.name },
      { path: `/tests/${K6Test.id}`, name: K6Test.name },
    ])
  }, [project, K6Test, setBreadcrumbs])

  useEffect(() => {
    if (runs.length) {
      setProjectId(runs[0].project_id)
    }
  }, [runs, setProjectId])

  const onRunTest = async (test: number) => {
    await ds.runTest(test)
    refresh()
    trackFeature('start_test')
  }

  const showBtn = () => {
    if (!K6Test) {
      return
    }

    const lastTestRun = runs.find((run) => run.id === K6Test.last_test_run_id)
    return canRunTest(K6Test, lastTestRun!)
  }

  const runningTestsMessage = (
    <h3>
      <Icon name="fa fa-spinner" /> Tests are running
    </h3>
  )

  return (
    <>
      <HorizontalGroup justify={'space-between'}>
        <div>{isRunActive && runningTestsMessage}</div>
        <HorizontalGroup justify={'flex-end'}>
          {showBtn() && (
            <Button key="settings" variant="secondary" onClick={() => onRunTest(+testId)}>
              Run Test
            </Button>
          )}
          <GoToK6Button type={ButtonTypes.Test} id={+testId}></GoToK6Button>
        </HorizontalGroup>
      </HorizontalGroup>
      <TrendingChart runs={runs} />
      <RunsTable runs={runs} isLoading={isLoading} loadNext={loadNext} isLoadingInitial={isLoadingInitial} />
    </>
  )
}
