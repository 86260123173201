import { ArrayDataFrame, dateTime, FieldConfig, LoadingState, PanelData, TimeRange } from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { LegendDisplayMode, PanelChrome } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import React, { useState, useEffect, useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { QueryType, TestRun } from 'types'
import { ClipBoard } from './Clipboard'

interface Props {
  data: any
  ds: K6DataSource
  run: TestRun
}

export const HttpChart = (props: Props) => {
  const { run, ds, data } = props
  const dataId = data.id
  const isRefreshing = false
  const onCancelQuery = () => {}
  const noop = () => {}

  const [chartData, setChartData] = useState<any>({ series: [] })

  const types = useMemo(() => {
    return [
      { method: 'value', metric: 'vus', unit: 'VUs', label: 'VUs', type: 'test_runs' },
      { method: 'rps', metric: 'http_reqs', unit: 'reqs/s', label: 'req rate', id: dataId, type: 'http_urls' },
      { method: '0.95', metric: 'http_req_duration', unit: 'ms', label: 'resp time', id: dataId, type: 'http_urls' },
      {
        method: 'nz_rps',
        metric: 'http_req_failed',
        unit: 'reqs/s',
        label: 'failed rate',
        id: dataId,
        type: 'http_urls',
      },
    ]
  }, [dataId])

  useEffect(() => {
    async function load(ds: K6DataSource) {
      const calls = types.map((t) => ds.fetchTimeSeries(t.type, run.id, t.metric, t.method, t.id))
      const response = await Promise.all(calls)

      let rangeValues: any[] = []
      const series = response.map((r, i) => {
        const values = r.value[0]?.values || []
        if (values.length > 0) {
          rangeValues = values
        }
        const label = types[i].label
        return values.map((v: any) => ({
          timestamp: new Date(v.timestamp),
          [label]: parseInt(v.value, 10),
        }))
      })

      const from = rangeValues[0].timestamp
      const to = rangeValues[rangeValues.length - 1].timestamp
      const r: TimeRange = {
        from: dateTime(from),
        to: dateTime(to),
        raw: { from, to },
      }

      const data = {
        series: series,
        range: r,
      }
      setChartData(data)
    }
    load(ds)
  }, [ds, run, types])

  const panelOptions = {
    legend: {
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
      calcs: [],
    },
    graph: {},
    tooltipOptions: {
      mode: 'multi',
    },
    tooltip: {
      mode: 'multi',
    },
  }

  const frames = types.map((t, i) => {
    const frame = new ArrayDataFrame(chartData.series[i] || [])
    if (frame.fields && frame.fields.length > 0) {
      const cfg: FieldConfig = { unit: t.unit }
      frame.fields[1].config = cfg
    }
    return frame
  })

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: frames,
    timeRange: chartData.range,
  }

  const fieldConfig = {
    defaults: {
      custom: {
        spanNulls: true,
      },
    },
    overrides: [],
  }

  return (
    <div>
      <ClipBoard name={ds.name} queryType={QueryType.URLS} testRun={run} itemId={dataId} />
      <AutoSizer disableHeight>
        {(size) => {
          return (
            <PanelChrome
              width={size.width}
              height={300}
              leftItems={[
                <PanelChrome.LoadingIndicator
                  loading={isRefreshing}
                  onCancel={onCancelQuery}
                  key="loading-indicator"
                />,
              ]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title=""
                  pluginId="timeseries"
                  onOptionsChange={noop}
                  // onChangeTimeRange={(t) => onChangeTimeRange(toTimeRange(t, timeZone))}
                  width={innerWidth}
                  height={innerHeight}
                  // timeZone={timeZone}
                  data={panelData}
                  options={panelOptions}
                  fieldConfig={fieldConfig}
                />
              )}
            </PanelChrome>
          )
        }}
      </AutoSizer>
    </div>
  )
}
