import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAppContext } from 'appContext'
import { TestsPage } from 'pages/TestsPage/TestsPage'
import { RunsPage } from 'pages/RunsPage/RunsPage'
import { RunDetails } from 'pages/RunDetails/RunDetails'

export const Routes = () => {
  const { projectId } = useAppContext()

  return (
    <Switch>
      <Route path="/projects/:projectId">
        <TestsPage />
      </Route>
      <Route path="/tests/:testId">
        <RunsPage />
      </Route>
      <Route path="/runs/:runId">
        <RunDetails />
      </Route>
      <Route path={'/'}>
        <Redirect to={`/projects/${projectId}`} />
      </Route>
    </Switch>
  )
}
