import { useFetch } from './useFetch'
import { useDatasource } from 'datasourceContext'

export const useChecks = (run: { id: number }) => {
  const { ds } = useDatasource()

  return useFetch([run, 'checks'], (run) => ds.fetchChecks(run.id), {
    useStickyData: true,
  })
}
